import {useCallback, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
// actions
import {getCountriesRequestAction} from 'redux/actions/countries/getCountriesRequestAction';
import {ICountriesData} from 'redux/actions/countries/getCountriesSuccesstAction';
import {IStoreState} from 'types';
import {ICountry} from 'types/country-types';

export const useGetCurrentCountry = () => {
    const {currentCountry} = useSelector(
        (state: IStoreState) => state.countries
    );

    return currentCountry || 1;
};

export const useFetchCountries = ({
    page = 1,
    perPage = 100,
    dataExist = false,
}: {
    page?: number | undefined;
    perPage?: number | undefined;
    dataExist?: boolean;
} = {}) => {
    const {currentCountry, data: countries} = useSelector(
        (state: IStoreState) => state.countries
    );
    const dispatch = useDispatch();

    const fetchCountries = useCallback(async () => {
        if (countries && countries.length > 0 && currentCountry) return;
        return await new Promise((resolve, reject) => {
            dispatch(
                getCountriesRequestAction(
                    {page, perPage},
                    (error, data?: ICountriesData) => {
                        const firstCountry: ICountry | null =
                            data?.data?.[0] || null;
                        if (!error && !!firstCountry) {
                            return resolve(true);
                        }
                        return reject(error);
                    }
                )
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, page, perPage, dataExist]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);
};
